import React from 'react';
import procesosTIImage from "../assets/images/procesos-ti.png";
import desarrolloImage from "../assets/images/desarrollo.png";
import softwareImage from "../assets/images/software.png";
import empresarialImage from "../assets/images/empresarial.png";
import cienciaDatosImage from "../assets/images/ciencia-datos.png";
import aplicacionesImage from "../assets/images/aplicaciones.png";

function Servicios() {
    return (
        <div className="container-fluid" id="Servicios" style={{ "marginTop": "5%" }}>
            <div className="row">
                <div className="col-sm-12 scrollflow -pop -opacity" id="Titulo-Servicios" style={{ "paddingBottom": "5%" }}>
                    <h1 style={{ "borderLeftStyle": "solid", "borderLeftWidth": "3px", "borderLeftColor": "#67C6DD", "paddingLeft": "15px", "fontWeight": "bold", "paddingBottom": "0px", "paddingTop": "0px" }}>SERVICIOS</h1>
                </div>
                <div className="col-lg-5 col-sm-6 scrollflow -slide-right -opacity Tipo-ServicioC" id="Tipo-Servicio" style={{ "paddingTop": "1%" }}>
                    <div id="Imagen-Servicio" className="Servicio-Img-Izq" >
                        <img className="img-fluid" src={procesosTIImage} alt="ProcesosTI" />
                    </div>
                    <div id="Info-Servicio" style={{ "paddingLeft": "10px" }}>
                        <h3>Procesos de TI</h3>
                        <p>Consultoría de procesos de TI</p>
                    </div>
                </div>
                <div className="col-lg-5 offset-lg-1 col-sm-6 scrollflow -slide-left -opacity Tipo-ServicioC" id="Tipo-Servicio" style={{ "paddingTop": "1%" }}>
                    <div id="Imagen-Servicio" className="Servicio-Img-Izq">
                        <img className="img-fluid" src={desarrolloImage} alt="Desarrollo" />
                    </div>
                    <div id="Info-Servicio" style={{ "paddingLeft": "10px" }}>
                        <h3>Desarrollo</h3>
                        <p>Desarrollo y administración de proyectos</p>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-6 scrollflow -slide-right -opacity Tipo-ServicioC" id="Tipo-Servicio" style={{ "paddingTop": "1%" }}>
                    <div id="Info-Servicio" style={{ "paddingLeft": "10px" }}>
                        <h3>Software</h3>
                        <p>Desarrollo de soluciones en software a la medida</p>
                    </div>
                    <div id="Imagen-Servicio">
                        <img className="img-fluid" src={softwareImage} alt="Software" />
                    </div>
                </div>
                <div className="col-lg-5 offset-lg-1 col-sm-6 scrollflow -slide-left -opacity Tipo-ServicioC" id="Tipo-Servicio" style={{ "paddingTop": "1%" }}>
                    <div id="Info-Servicio" style={{ "paddingLeft": "10px" }}>
                        <h3>Empresarial</h3>
                        <p>Desarrollo empresarial sustentable y comercialización</p>
                    </div>
                    <div id="Imagen-Servicio" className="Servicio-Img-Der">
                        <img className="img-fluid" src={empresarialImage} alt="Empresarial" />
                    </div>
                </div>
                <div className="col-lg-5  col-sm-6 scrollflow -slide-right -opacity Tipo-ServicioC" id="Tipo-Servicio" style={{ "paddingTop": "1%" }}>
                    <div id="Imagen-Servicio" className="Servicio-Img-Izq">
                        <img className="img-fluid" src={cienciaDatosImage} alt="CienciaDeDatos" />
                    </div>
                    <div id="Info-Servicio" style={{ "paddingLeft": "10px" }}>
                        <h3>Ciencia de datos</h3>
                        <p>Implementación de herramientas de Ciencia de Datos en diversos procesos de nuestros clientes</p>
                    </div>
                </div>
                <div className="col-lg-5 offset-lg-1 col-sm-6 scrollflow -slide-left -opacity Tipo-ServicioC" id="Tipo-Servicio" style={{ "paddingTop": "1%" }}>
                    <div id="Imagen-Servicio" className="Servicio-Img-Izq">
                        <img className="img-fluid" src={aplicacionesImage} alt="Aplicaciones" />
                    </div>
                    <div id="Info-Servicio" style={{ "paddingLeft": "10px" }}>
                        <h3>Aplicaciones</h3>
                        <p>Desarrollo de aplicaciones móviles para dispositivos Android y iOS</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Servicios