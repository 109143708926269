import React from "react";
import dassInfo from "../assets/images/daas-logo-inicio.png";
import dassMasInfo from "../assets/images/boton-mas-info.png";
import SectionCommon from "./base/sectionCommon";

function Inicio() {
    return (
        <SectionCommon id="Inicio">
            <div className="col-12">
                <div id="Logo-inicio">
                    <img className="img-fluid" src={dassInfo} alt="Logo Dass" style={{ "width": "100%", "maxWidth": "300px" }}/>
                </div>
                <div id="Mas-info">
                    <a href="#Contacto">
                        <img className="img-fluid" src={dassMasInfo} alt="Boton Mas info" style={{ "width": "100%", "maxWidth": "120px" }}/>
                    </a>
                </div>
            </div>
        </SectionCommon>
    );
}

export default Inicio;