import React from 'react'
import SectionCommon from './base/sectionCommon';

function Nosotros() {
    const styleTitulo = {
        "borderLeftStyle": "solid",
        "borderLeftWidth": "3px",
        "borderLeftColor": "#67C6DD",
        "paddingLeft": "15px",
        "fontWeight": "bold"
    }
    return (
        <>
            <SectionCommon id="Nosotros">
                <div className="col-sm-12">
                    <div id="Titulo-Nosotros" className="scrollflow -pop">
                        <h1 style={styleTitulo}>NOSOTROS</h1>
                    </div>
                </div>

                <div className="col-sm-11 col-md-8 col-lg-6 col-xl-6">
                    <div id="info-Nosotros" style={{ "paddingTop": "2%" }} className="scrollflow -slide-top -opacity">
                        <p>
                            <span>DASS</span> es una empresa orgullosamente mexicana especializada en tecnologías de la información y ciencia de datos, enfocada a desarrollar soluciones que mejoren los procesos de nuestros clientes en diversos sectores, en donde la innovación, la investigación y el desarrollo tecnológico son los factores determinantes en la generación de soluciones eficientes y altamente competitivas.
                        </p>
                    </div>
                </div>
            </SectionCommon>

            <div id="Mision" className="container-fluid" style={{ "marginTop": "10%" }}>
                <div className="col-md-12" style={{ "paddingLeft": "0px", "paddingRight": "0px" }}>
                    <div id="Carousel-Mision-Vision" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#Carousel-Mision-Vision" data-slide-to="0" className="active"></li>
                            <li data-target="#Carousel-Mision-Vision" data-slide-to="1"></li>
                        </ol>

                        <div className="carousel-inner" role="listbox">
                            <div className="carousel-item active" id="Mision-BG">
                                <div className="carousel-caption" style={{ "top": "0px" }}>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-8 offset-lg-2">
                                            <div id="Titulo-Mision">
                                                <h1 style={{ "borderLeftStyle": "solid", "borderLeftWidth": "3px", "borderLeftColor": "#67C6DD", "paddingLeft": "15px", "fontWeight": "bold" }}>MISIÓN</h1>
                                            </div>
                                        </div>
                                        <div className="col-lg-10 col-xl-6 offset-xl-2 col-sm-12 col-md-8">
                                            <div id="Info-Mision" style={{ "paddingTop": "5%" }} className="scrollflow -slide-right">
                                                <p>
                                                    Nuestra misión es generar soluciones tecnológicas innovadoras que permitan a nuestros clientes optimizar sus procesos y dar valor agregado a sus productos, integrando nuestra experiencia y la de nuestros colaboradores para hacerlos más competitivos en la economía global.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item" id="Vision-BG">
                                <div className="carousel-caption" style={{ "top": "0px" }}>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-8 offset-lg-2">
                                            <div id="Titulo-Vision" className="scrollflow -pop  -opacity">
                                                <h1 style={{ "borderLeftStyle": "solid", "borderLeftWidth": "3px", "borderLeftColor": "#67C6DD", "paddingLeft": "15px", "fontWeight": "bold" }}>VISIÓN</h1>
                                            </div>
                                        </div>
                                        <div className="col-lg-10 col-xl-6 offset-xl-2 col-sm-12 col-md-8">
                                            <div id="Info-Vision" style={{ "paddingTop": "1%" }} className="scrollflow -slide-right">
                                                <p>
                                                    Ser una empresa líder en el desarrollo de soluciones tecnológicas adecuadas a las necesidades de nuestros clientes.
                                                </p>
                                            </div>
                                            <div id="Puntos-Vision">
                                                <ul>
                                                    <li>Equipo multidisciplinario comprometido con la Innovación.</li>
                                                    <li>Desarrollamos software bajo altos estándares de calidad para la mejora de procesos agroindustriales.</li>
                                                    <li>Implementamos de soluciones que generen valor al cliente y lo haga competitivo.</li>
                                                    <li>Incorporamos ciencia de datos para convertir la información en una ventaja competitiva al perfeccionar los productos y servicios.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Nosotros