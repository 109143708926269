import React from 'react';

import globalStarImage from "../assets/images/globalStar.png";
import unamSmallImage from "../assets/images/unam-small.png";
import lancisSmallImage from "../assets/images/lancis-small.png";

function Aliados() {
    return (
        <>
            <div className="container-fluid" id="Aliados" style={{ "marginTop": "10%" }}>
                <div className="row">
                    <div className="col-lg-4 offset-lg-2 col-12 col order-lg-last scrollflow -pop  -opacity" id="Titulo-Aliados">
                        <h1 style={{ "borderLeftStyle": "solid", "borderLeftWidth": "3px", "borderLeftColor": "#67C6DD", "paddingLeft": "15px", "fontWeight": "bold", "paddingBottom": "0px", "paddingTop": "0px" }}>ALIADOS</h1>
                    </div>
                    <div className="col-lg-6 col-12 align-self-center" id="Info-Aliados" >
                        <div id="Carousel-Aliados" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img className="w-50" src={globalStarImage} style={{ "height": "100%", "width": "100" }} alt="First slide" />
                                </div>
                                <div className="carousel-item">
                                    <img className="w-25" src={unamSmallImage} style={{ "height": "100%", "width": "100" }} alt="First slide" />
                                </div>
                                <div className="carousel-item">
                                    <img className="w-25" src={lancisSmallImage} style={{ "height": "100%", "width": "100" }} alt="First slide" />
                                </div>
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#Carousel-Aliados" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previo</span>
                        </a>
                        <a className="carousel-control-next" href="#Carousel-Aliados" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Siguiente</span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Aliados