function SectionCommon({ id, children }) {
    return (
        <section className="container-fluid" id={id}>
            <div className="row">
                {children}
            </div>
        </section>
    );
}

export default SectionCommon;