import React from 'react';
import logo from '../logo.png';
import ItemMenu from './base/itemMenu';

function Navbar() {
    return (
        <header>
            <nav id="menu" className="navbar fixed-top navbar-expand-lg navbar-light navbar-toggleable-sm" style={{ "paddingBottom": "0%", "paddingRight": "2%" }}>
                <div id="logo-Dass" >
                    <a className="navbar-brand mt-0 ml-0" href="#Inicio">
                        <img id="imagen-logo" src={logo} alt="Logo Dass" className="img-responsive d-inline-block align-top" />
                    </a>
                </div>
                <div id="menu-boton">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse mt-4 fontWeight-bold color collapse show mr-5" id="navbarNav" style={{ "fontSize": "20px" }}>
                    <div className="navbar-nav text-center">
                        <ul className="navbar-nav">
                            <ItemMenu itemHref="Inicio" itemName="Inicio" />
                            <ItemMenu itemHref="Nosotros" itemName="Nosotros" />
                            <ItemMenu itemHref="Servicios" itemName="Servicios" />
                            <ItemMenu itemHref="Aliados" itemName="Aliados" />
                            {/*<ItemMenu itemHref="Contacto" itemName="Contacto" />*/}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Navbar;