import cn from 'classnames';

function ItemMenu({ itemHref, itemName }) {
    return (
        <li className="nav-item">
            <a href={`#${itemHref}`} className={cn({
                "nav-link active": true
            })}>
                {itemName}
            </a>
        </li>
    );
}

export default ItemMenu;