import React from 'react';
import Navbar from './components/navbar';
import Inicio from './components/inicio';
import Nosotros from './components/nosotros';
import Servicios from './components/servicios';
import Aliados from './components/aliados';
import Contacto from './components/contacto';

function App() {
  return (
    <>
      <div className='flex flex-col w-full'>
        <Navbar />
        <Inicio />
        <Nosotros />
        <Servicios />
        <Aliados />
        {/* <Contacto /> */}
      </div>
    </>
  )
}

export default App;